import { NzModalService } from 'ng-zorro-antd/modal';
import { FieldElementTypes } from './../../models/form';
import { Question } from './../../models/webform';
import { Component, Input, DoCheck, Output, EventEmitter, OnInit } from '@angular/core';
import { SignatureComponent } from '../answer/signature/signature.component';

@Component({
	selector: 'app-question',
	templateUrl: './question.component.html',
	styleUrls: [ './question.component.css' ]
})
export class QuestionComponent implements DoCheck, OnInit {

	@Input('question') question: Question | undefined;
	@Input('formViewType') formViewType: string;
	@Input('haveError') set haveError(haveError: string) {
		this.cardClass = (haveError) ? "single-page-view error-card" : "single-page-view";
	}

	@Output('status') status: EventEmitter<boolean> = new EventEmitter();
	cardClass: string = "single-question-view-card";
	titleClass: string = "single-question-title";
	loading: boolean = true;

	constructor(
		private _modalService: NzModalService
	){}

	ngOnInit(): void {
		this.loading = true;
		switch (this.formViewType) {
			case "SINGLE_PAGE_VIEW":
				this.cardClass = "single-page-view";
				this.titleClass = "single-page-question-title";
				break;
			default:
				this.cardClass = "single-question-view-card";
				this.titleClass = "single-question-title";
		}
		this.loading = false;
	}
	
	ngDoCheck(): void {
		this.checkQuestionStatus();
	}

	checkQuestionStatus(): void {
		this.status.emit(false);

		if (!this.question) return;

		if (!this.question.required) {
			this.status.emit(true);
			return;
		}

		if (this.question.answer.length && FieldElementTypes.PRE_FILLED === this.question.type) {
			this.status.emit(true);
			return;
		}

		if (
			this.question.required &&
			this.question.answer !== undefined &&
			!!this.question.answer.toString().trim() &&
			this.question.valid
		)
			this.status.emit(true);
	}

	openCanvas(question){
		this._modalService.create({
			nzContent: SignatureComponent,
			nzComponentParams: {
				question: question
			},
			nzTitle: "Signature",
			nzMaskClosable: false,
			nzFooter: null
		})
	}
}
