import { FormScoreCalculationService } from './../../form-score-calculation.service';
import { CreateEvaluatedFormRequest } from './../../models/eval-form-request';
import { Form, Schema, SectionElement } from './../../models/form';
import { Result } from './../../models/result';
import { FormsService } from './../../forms.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Question, Section, WebForm } from '../../models/webform';
import { Title } from '@angular/platform-browser';
import { NzModalService } from 'ng-zorro-antd/modal';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ActionItemDetails } from '../../models/action-item-details';
import { SMSFormResponse } from '../../models/sms-form-response';
import { Location } from '@angular/common';
import { FormHelpComponent } from '../form-help/form-help.component';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
	selector: 'app-sms-form',
	templateUrl: './sms-form.component.html',
	styleUrls: ['./sms-form.component.css']
})
export class SmsFormComponent implements OnInit {

	currentSection: number = 0;
	currentQuestion: number = 0;
	questionIndex: number = 0;
	totalQuestions: number = 0;
	questionStatus: boolean = false;
	formSubmitting: boolean = false;
	patientId: string;
	tenantId: string;
	tenantName: string;
	tenantLogoUrl: string;
	actionItemId: string;
	formId: string = '';
	formTitle: string;
	originalForm: Form;
	webForm: WebForm | undefined;
	jwtToken: string;
	singleForm: boolean;
	fromUserId: string
	formViewType: string;
	formLoading: boolean;
	deviceInfo: any;

	constructor(
		private _formsService: FormsService,
		private _route: ActivatedRoute,
		private _router: Router,
		private _titleService: Title,
		private _modalService: NzModalService,
		private _jwtHelper: JwtHelperService,
		private _location: Location,
		private deviceService: DeviceDetectorService,
		private _formScoreCalculationService: FormScoreCalculationService
	) { }

	ngOnInit(): void {
		this.formLoading = true;
		this.deviceInfo = this.deviceService.getDeviceInfo();
		this._route.params.forEach((params: Params) => {
			this.jwtToken = params['jwtToken'];
			let decodedToken = this._jwtHelper.decodeToken(this.jwtToken);
			this.tenantId = decodedToken['tenantId'];
			this.tenantLogoUrl = decodedToken['tenantLogoUrl'];
			this.tenantName = decodedToken['tenantName'];
			this.patientId = decodedToken['patientId'];
			this.fromUserId = decodedToken['fromUserId'];
			this.actionItemId = params['actionItemId'];
			var formBlueprintId = params['formBlueprintId'];

			this.getActionItemDetails(this.actionItemId, formBlueprintId);

		});

		this._route.queryParams.forEach((params: Params) => {
			this.singleForm = params['singleForm'] === 'true';
		});
	}

	setTitle(title: string) {
		this.formTitle = title;
		this._titleService.setTitle(title);
	}

	getActionItemsForPatient(patientId: string){
		this._formsService.fetchFormActionItemsForPatient(patientId).subscribe((result: ActionItemDetails[]) => {
			if(result.length == 0){
				this._router.navigate([ 'no-pending-forms' ]);
			}else{
				var formActionItem = result[0]
				var formParameters: Map<string, string> = new Map(Object.entries(JSON.parse(formActionItem.parameters)));
			
				var formBlueprintId: string = formParameters.get("formBlueprintId");
				this.getActionItemDetails(formActionItem.id, formBlueprintId);
			}
		})
	}

	getActionItemDetails(actionItemId: string, formId: string) {
		this._formsService.fetchActionItemDetails(actionItemId).subscribe((result: ActionItemDetails) => {
			if (!result.id) this._router.navigate(['not-found']);
			this.actionItemId = result.id;
			let actionItemDetails = result;

			// The action item/ form is already completed
			if (actionItemDetails.completed) this._router.navigate(['form-submitted'], {
				queryParams: {
					'jwtToken': this.jwtToken,
					'pendingForms': 0,
					"showMain": true,
					"singleForm": this.singleForm
				}
			});


			// The action item is archived i.e, the task is removed or the careplan has been suspended
			if (actionItemDetails.archived || actionItemDetails.active == false) this._router.navigate(['not-found']);

			this.getFormById(formId);
		})
	}

	getFormById(formId: string) {
		this.formLoading = true;
		this._formsService.fetchSMSFormById(formId, this.patientId).subscribe((res: Result) => {
			console.log("RES   ::: ",res.data)
			if (!res.data) this._router.navigate(['not-found']);

			this.originalForm = res.data;
			const isMobile = this.deviceService.isMobile();
			const isTablet = this.deviceService.isTablet();
			// const isDesktopDevice = this.deviceService.isDesktop();
			// console.log("deviceInfo : ", this.deviceInfo);
			console.log("isMobile : ",isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
			console.log("isTablet : ", isTablet);  // returns if the device us a tablet (iPad etc)
			console.log("isDesktopDevice : ", this.originalForm.schema.formViewType);

			if (this.originalForm.schema.formViewType == 'SINGLE_PAGE_VIEW' && this.deviceService.isMobile()){
				console.log("Not Comp : ");
				this._router.navigate(['not-compatible']);
				
			}
				

			
			this.formViewType = "SINGLE_QUESTION_VIEW";
			this.setTitle(res.data.title);
			this.mapFormToWebForm(res.data);
			this.formLoading = false;
		});


	}

	mapFormToWebForm(form: Form) {
		this.currentSection = 0;
		this.currentQuestion = 0;
		this.questionIndex = 0;
		this.totalQuestions = 0;
		this.questionStatus = false;
		this.formSubmitting = false;
		const sections: Section[] = this.mapSectionsFromForm(form.schema);

		this.webForm = {
			title: form.title,
			type: form.type,
			sections: sections
		} as WebForm;
	}

	mapSectionsFromForm(schema: Schema) {

		const sections: Section[] = [];

		schema.elements.forEach((element: SectionElement, sectionIndex: number) => {
			const questions: Question[] = [];

			element.elements.forEach((questionElement: SectionElement) => {
				const question = {
					title: questionElement.title,
					type: questionElement.type,
					answer: questionElement.answer,
					option: questionElement.options,
					required: questionElement.required,
					scored: questionElement.scored,
					sectionIndex: sectionIndex,
					helpText: questionElement.helpText,
					resultConditions: questionElement.resultConditions,
					tableStructure: questionElement.tableStructure,
					signaturePad: questionElement.signaturePad
				} as Question;

				this.totalQuestions += 1;
				questions.push(question);
			});

			const section = {
				title: element.title,
				questions: questions,
				totalQuestions: questions.length
			} as Section;

			sections.push(section);
		});

		return sections;
	}

	changeSection() {
		if (!this.webForm) return;

		if (
			this.webForm.sections[this.currentSection].totalQuestions === this.questionIndex &&
			this.webForm.sections.length - 1 > this.currentSection
		) {
			this.questionIndex = 0;
			this.currentSection += 1;
			return;
		}

		if (this.questionIndex === -1 && this.currentSection > 0) {
			this.currentSection -= 1;
			this.questionIndex = this.webForm.sections[this.currentSection].totalQuestions - 1;
		}
	}

	next() {
		if (this.currentQuestion < this.totalQuestions - 1) {
			this.currentQuestion += 1;
			this.questionIndex += 1;
			this.changeSection();
		}
	}

	previous() {
		if (this.currentQuestion > 0) {
			this.currentQuestion -= 1;
			this.questionIndex -= 1;
			this.changeSection();
		}
	}

	createFormSubmissionObject(): CreateEvaluatedFormRequest {
		const evalutedScore: number = this._formScoreCalculationService.calculate(this.originalForm, this.webForm);
		const identifiedRisk: { risk: string, alert: boolean } = this._formScoreCalculationService.identifiedRisk(this.originalForm, evalutedScore);

		const evalFormRequest: CreateEvaluatedFormRequest = {
			evaluationForm: this.originalForm.schema,
			formBlueprintId: this.formId,
			patientId: this.patientId,
			tenantId: this.tenantId,
			actionItemId: this.actionItemId,
			evalutedScore: evalutedScore,
			identifiedRisk: identifiedRisk?.risk,
			shouldSendAlert: identifiedRisk?.alert,
			fromUserId: this.fromUserId,
		} as CreateEvaluatedFormRequest;

		return evalFormRequest;
	}

	submit() {
		this.formSubmitting = true;
		const evalFormRequest: CreateEvaluatedFormRequest = this.createFormSubmissionObject();

		this._formsService.submitSMSForm(evalFormRequest).subscribe(
			(formResponse: SMSFormResponse) => {
				var formResultData = formResponse.data;
				var pendingForms = formResultData.pendingFormActionItems.length
				if (pendingForms > 0) {
					var formActionItem = formResultData.pendingFormActionItems[0]
					var formActionItemId = formActionItem?.id;
					var formParameters: Map<string, string> = new Map(Object.entries(JSON.parse(formActionItem.parameters)));
					var formBlueprintId: string = formParameters.get("formBlueprintId");
					this._router.navigate(['form-submitted'], {
						queryParams: {
							"pendingForms": pendingForms,
							"actionItemId": formActionItemId,
							"formTitle": this.formTitle,
							"formId": formBlueprintId,
							"jwtToken": this.jwtToken,
							"showMain": true,
							"singleForm": this.singleForm
						}
					});
				} else {
					this._router.navigate(['form-submitted'], {
						queryParams: {
							"pendingForms": pendingForms,
							"formTitle": this.formTitle,
							"jwtToken": this.jwtToken,
							"showMain": true,
							"singleForm": this.singleForm
						}
					});
				}
			},
			() => {
				this._modalService.confirm({
					nzContent: 'Something went wrong while submitting the form, please try again later!',
					nzCancelText: null,
					nzOnOk: () => this.close(),
					nzClosable: false,
					nzMaskClosable: false
				});
			}
		);
	}

	close(state: boolean = false, actionItemId: string = null, formBlueprintId: string = null) {
		if (state) {
			this.getActionItemDetails(actionItemId, formBlueprintId);
		} else {
			const closer: Object = {
				success: state,
				canClose: true
			};
			JSON.stringify(closer);
		}
	}

	onBackPressed() {
		this._modalService.confirm({
			nzIconType: null,
			nzTitle: "<b>Are you sure you want to leave?</b>",
			nzContent: "<p>You will lose all the progress in the form done till now.</p>",
			nzCancelText: "Cancel",
			nzOkText: "Leave",
			nzWidth: "80%",
			nzOnOk: () => this._location.back(),
			nzClosable: false,
			nzMaskClosable: false
		});
	}

	showHelp() {
		this._modalService.create({
			nzContent: FormHelpComponent,
			nzComponentParams: {
			},
			nzWidth: "80%",
			nzClosable: true,
			nzMaskClosable: true,
			nzCloseIcon: "./../../../assets/close",
			nzFooter: null
		});
	}

}
