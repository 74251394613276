<nz-card *ngIf="question && !loading" [ngClass]="cardClass" [nzHoverable]="true">
    <h4 [ngClass]="titleClass">
        {{ question?.title | dynamicTranslate | async }}
        <span *ngIf="question?.helpText"> &nbsp;({{ question?.helpText | dynamicTranslate | async }})</span>
    </h4>

    <nz-divider></nz-divider>

    <ng-container [ngSwitch]="question?.type">
        <app-radio *ngSwitchCase="'RADIO'" [question]="question" [formViewType]="formViewType"></app-radio>

        <app-checkbox *ngSwitchCase="'CHECKBOX'" [question]="question" [formViewType]="formViewType"></app-checkbox>

        <app-text *ngSwitchCase="'INPUT'" [question]="question" [formViewType]="formViewType"></app-text>

        <app-date *ngSwitchCase="'DATE'" [question]="question" [formViewType]="formViewType"></app-date>

        <app-prefilled *ngSwitchCase="'PRE_FILLED'" [question]="question" [formViewType]="formViewType"></app-prefilled>

        <app-arithmetic *ngSwitchCase="'ARITHMETIC'" [question]="question" [formViewType]="formViewType"></app-arithmetic>

        <table-component *ngSwitchCase="'TABLE'" [question]="question" [formViewType]="formViewType"></table-component>

        <div *ngSwitchCase="'SIGNATURE'" class="signature-container" (click)="openCanvas(question)">
            
            <span nz-typography nzType="secondary" class="signature-placeholder-text"> 
                <ng-container *ngIf="question?.answer[0] == undefined || question?.answer[0]?.signature?.length == 0"> Click here to Sign </ng-container>
                    
                <ng-container *ngIf="question?.answer[0] != undefined && question?.answer[0]?.signature?.length != 0"> If you wish to resubmit your signature, please click here </ng-container>
                
            </span>
            <div *ngIf="question.answer.length > 0" class="signature-preview">
                <img [src]="question?.answer[0]?.signature"/>
            </div>
        </div>

        <ng-container *ngIf="'PARAGRAPH' === question?.type">
            <span [innerHTML]="question?.answer[0]"></span>
        </ng-container>
    </ng-container>
</nz-card>