import { Component, Input, OnInit } from '@angular/core';
import { CellFieldType, CellStructure, TableStructure } from '../../../models/table-structure';
import { Question } from '../../../models/webform';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'table-component',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent implements OnInit {

  @Input() question: Question | undefined;
  
  rows: Array<number> = [];
  loadingTable: boolean = false;
  width: string;

  constructor(
    private _notificationService: NzNotificationService
  ) { }

  ngOnInit(): void {
    if (this.question.tableStructure == undefined || this.question.tableStructure == null)
      this.question.tableStructure = new TableStructure();
  }

  newRow(){

    let newLastRowIndex = (this.question.tableStructure.table.length);

    if(newLastRowIndex >= 15){
      this._notificationService.warning("Table", "Maximum number of rows reached.")
      return;
    }

    let columnCount: number = this.question.tableStructure.table[0].length;

    this.loadingTable = true;
    this.question.tableStructure.table[newLastRowIndex] = [];
    for (let columnIndex = 0; columnIndex < columnCount; columnIndex++) {
      var cell = new CellStructure();

      if(newLastRowIndex != 0){
        cell.type = this.question.tableStructure.table[newLastRowIndex - 1][columnIndex].type;
        cell.options = this.question.tableStructure.table[newLastRowIndex-1][columnIndex].options;
        cell.value = "";
        cell.rows = 1;
      }else{
        cell.type = CellFieldType.TEXT;
        cell.value = "";
        cell.rows = 1;
      }
      this.question.tableStructure.table[newLastRowIndex][columnIndex] = cell;
    }

    this.loadingTable = false;
  }

  deleteRow(rowIndex: number){

    if (this.question.tableStructure.table.length <= 2){
      // this._notificationService.error(this.question.tableStructure.title, "Atleast 1 row is needed.")
      return;
    }

    this.loadingTable = true;
    this.question.tableStructure.table.splice(rowIndex, 1);
    this.loadingTable = false;
  }

  getClass(rowIndex: number, columnIndex: number): string{

    if (this.question.tableStructure.horizontal && rowIndex == 0)
      return "th";

    if (!this.question.tableStructure.horizontal && columnIndex == 0)
      return "th th-vertical";

    return "td";

  }

  isHeader(rowIndex: number, columnIndex: number): boolean {

    if (this.question.tableStructure.horizontal && rowIndex == 0)
      return true;

    if (!this.question.tableStructure.horizontal && columnIndex == 0)
      return true;

    return false;

  }

  onValueUpdate(event, rowIndex, columnIndex){
    var cell = new CellStructure();
    cell.type = CellFieldType.TEXT;
    cell.value = event.target.value;
    this.question.tableStructure.table[rowIndex][columnIndex] = cell;
  }

}
